define('common/components/learn-router/application',[
    'app',
    'vendor/nprogress/nprogress',
    'common/components/learn-router/views/layout',
    'common/components/learn-router/dependency-manager'

], function(app, nprogress, LayoutView, dependencyManager) {
    'use strict';

    return Mn.Application.extend({
        "appArgs": null,
        "currentAppType": null,
        "currentRoute": null,
        "currentXHR": null,
        "currentNativeXHR": null,
        "fetchParams": null,
        "isFirstLoad": true,

        "referrer": window.location.href,

        "onStart": function (params) {

            window.DeTracker && DeTracker.extendPageData({
                "url": document.referrer
            });
            this.layoutModel = new Backbone.Model({
                "init_data": app.initData.toJSON()
            });
            this.layout = new LayoutView({
                "model": this.layoutModel,
                "user": new Backbone.Model(app.initData.get("user")),
                "navigation": new Backbone.Model()
            });

            nprogress.configure({
                "easing": "easeInQuint",
                "trickleSpeed": 800
            });

            this.appArgs = {
                "App": null,
                "action": null,
                "appName": null,
                "data": null
            };

            this.listenTo(this.layout, 'do-form-post', this.doFormPost);
        },

        "loadApp": function(appName, action, deepAction){
            action = deepAction ? action + "#" + deepAction : action;
            if (this.currentRoute !== Backbone.history.fragment) {
                this.previousRoute = this.currentRoute;
                this.currentRoute = Backbone.history.fragment;
            }

            var preLoadedData = Backbone.Radio.channel('learn-router').request('router:next-skip-load');

            if (!preLoadedData) {
                if (!this.isFirstLoad) {
                    nprogress.start();
                }
                require(["dist/apps/" + appName + "/main"], _.bind(function (App) {
                    // update appArgs
                    _.extend(this.appArgs, {
                        "App": App,
                        "action": action,
                        "appName": appName
                    });

                    if (this.isFirstLoad) {
                        this.startApp(App, action, appName, this.appArgs.data);
                    } else if (this.appArgs.data) {
                        this.appReady(App, action, appName, this.appArgs.data);
                    } else {
                        this.fetchData(App, action, appName);
                    }

                    this.appArgs.data = null;
                    this.isFirstLoad = false;
                }, this));
            } else if (preLoadedData.page_data) {
                require(["dist/apps/" + appName + "/main"], _.bind(function (App) {
                    // update appArgs
                    _.extend(this.appArgs, {
                        "App": App,
                        "action": action,
                        "appName": appName
                    });
                    this.appReady(App, action, appName, preLoadedData);
                }, this));
            }
        },

        "appReady": function(App, action, appName, data){
            // clear init data...
            // but persist anything that wont be returned by render2
            var updatedInitData = {
                "experiments": app.initData.get("experiments"),
                "feature_flags": app.initData.get("feature_flags"),
                "product_settings": app.initData.get("product_settings"),
                "quicklist": app.initData.get("quicklist"),
                "session_ttl": app.initData.get("session_ttl")
            };
            app.initData.clear({"silent": true}).set(_.extend({}, updatedInitData, data.init_data), {"silent": true});

            // Update the `window.initData` object (some legacy code still uses this)
            // e.g. core/wwwroot/static/js/components/highlight-model.js
            window.initData = app.initData.toJSON();

            if (data.init_data.user) {
                this.layout.user.set(data.init_data.user);
            }

            var updatedData = _.extend({}, data, {
                "init_data": app.initData.toJSON(),
                "page_data": data.page_data
            });

            this.layoutModel.set(updatedData);

            if (this.currentApp) {
                // check if we should just update the model or destroy the app
                if (this.currentAppType === appName && this.currentApp.update && typeof this.currentApp.update === "function"){
                    this.loadDependsAnd(function(passAction, passAppName, passData){
                        this.currentApp.update({
                            "action": passAction,
                            "data": passData,
                            "previousRoute": this.previousRoute
                        });
                        this.layout.triggerMethod('redraw');
                    }, action, appName, updatedData);
                } else {
                    window.DeTracker && DeTracker.resetPageData();
                    this.currentApp.destroy();
                    data.init_data = app.initData.toJSON();
                    this.startApp(App, action, appName, data);
                }
            } else {
                // i dont think it will ever go into here? putting ga tracking here to see if it does
                if (typeof window.ga === "function") {
                    ga('send', 'event', 'learnrouter', "in_app_ready_no_current_app", window.location.href);
                }
                this.startApp(App, action, appName, data);
            }

            // redraw navigation if necessary
            if (data.page_data.navigation) {
                this.layout.navigation.set(data.page_data.navigation);
            }
            nprogress.done();
            if (window.ga){
                ga('set', 'page', window.location.pathname);
                ga('set', 'title', document.title);
                if (data.request && data.request.endpoint) {
                    ga('set', 'dimension3', 'View ' + data.request.endpoint);
                }

                if (data.page_data.subject_name) {
                    ga('set', 'dimension6', data.page_data.subject_name);
                } else {
                    ga('set', 'dimension6', null);
                }

                if (data.page_data.navigation) {
                    ga('set', 'dimension7', data.page_data.navigation.product_name);
                }

                if (data.init_data.asset && data.init_data.asset.type) {
                    ga('set', 'dimension8', data.init_data.asset.type.name);
                } else if (data.page_data.video && data.page_data.video.type) {
                    ga('set', 'dimension8', data.page_data.video.type.name);
                } else {
                    ga('set', 'dimension8', null);
                }

                if (data.page_data.topic && data.page_data.topic.subject_name) {
                    ga('set', 'dimension9', data.page_data.topic.subject_name);
                } else {
                    ga('set', 'dimension9', null);
                }

                ga('send', 'pageview');
            }
        },

        "startApp": function(App, action, appName, data){
            this.currentApp = new App();
            this.loadDependsAnd(this.reallyStartAppThisTime, action, appName, data);
        },

        "loadDependsAnd": function(cb, action, appName, data){
            if (this.currentApp.cssDepends) {

                var cssDepends = typeof this.currentApp.cssDepends === "function" ?
                    this.currentApp.cssDepends(data) : this.currentApp.cssDepends;

                dependencyManager.loadCSS(cssDepends, appName, window.paths.core_static, _.bind(function(){
                    // let the code take one step so cached css will load immediatly so you dont see
                    // a broken page
                    setTimeout(_.bind(function(){
                        cb.call(this, action, appName, data);
                    }, this), 1);
                }, this));
            } else {
                cb.call(this, action, appName, data);
            }
        },

        "reallyStartAppThisTime": function(action, appName, data){
            /*
            CSS dependencies needed to be loaded first, before we could do this. Now actually do it.
            */
            this.currentApp.start({
                "action": action,
                "data": data || null
            });
            this.currentAppType = appName;
            this.layout.triggerMethod('redraw');
        },

        "fetchData": function (App, action, appName) {
            if (this.currentXHR && this.currentXHR.readystate !== 4){
                this.currentXHR.abort();
                nprogress.set(0);
            }

            nprogress.inc(.1);

            var headers = {
                // Here, because we want to reload the page fully whenever static files were updated, we send the
                // cache busting v2 "cbstatic" key - in the case where since the learn router has been reloaded,
                // the cbstatic key has been updated (this implies at least some static files have been modified
                // in the various DE static hosting backends) the server will not process the request, and return a
                // 204 No Content (https://tools.ietf.org/html/rfc7231#section-6.3.5) response with a header
                // X-Location: path, which implies push state isn't usable & the client should navigate normally.
                "X-CBSK": window.dectx.cbstatic_key,

                // tells the server to respond with a timeout
                // if the request was in the queue for 5 seconds or more
                "X-Request-Deadline": "s=5.0",

                "X-Push-State-Referrer": this.referrer
            };

            // set uid in a header, backend will test that this is the logged in user
            // and not return quickist and product settings
            if (app.initData.get("user")) {
                headers["X-UID"] = app.initData.get("user").id;
            }

            if (Backbone.Radio.channel('learn-router').request('router:next-load-light')) {
                headers["X-Load-Light"] = true;
            }

            this.currentXHR = $.ajax({
                "context": this,
                "url": window.location.href,
                "headers": headers,
                "xhr": this.xhrProgress,
                "success": function(data) {
                    this.referrer = window.location.href;
                    if (this.currentXHR.status === 204) {
                        // Note: the resphandler isn't in control of the 204s, and there's no data on a No Content,
                        // so compare to the actual 204 status code

                        var responseURL = this.currentXHR.getResponseHeader('X-Location');

                        if (responseURL) {
                            // we technically are "done" with AJAX, and now the browser's load in progress indicator UI
                            // will take over showing the user we're still doing something.
                            nprogress.set(1);

                            if (responseURL === window.location.pathname) {
                                window.location.reload();
                            } else {
                                window.location.href = responseURL;
                            }
                        }
                    } else if (data.status && data.status === 401) {
                        // the resphandler is actually not using the real status code here, so we look in the data
                        // this will be fixed in EDU-5100
                        window.location.href = "/learn/signin?next=" + encodeURIComponent(data.next);
                    } else {
                        this.layout.clearError();
                        this.appReady(App, action, appName, data);
                    }
                },
                "error": function(xhr){
                    // if xhr was not aborted... render the error page
                    // xhr would be aborted if the end user clicked
                    // some other do push state link on the page
                    // while there was aleady an ajax request running in here
                    if (xhr.statusText !== "abort") {

                        // clear out the page, and make way for the error page
                        window.DeTracker && DeTracker.resetPageData();
                        this.currentApp && this.currentApp.destroy();
                        this.currentApp = null;
                        nprogress.done();

                        // handle jquery timeout
                        if (xhr.statusText === "timeout") {
                            this.layout.showClientSideTimeoutPage();
                            // send a ga event too, so we can see
                            // how often this happens...
                            typeof window.ga === "function" &&
                                ga('send', 'event', 'learnrouter', 'timeout', window.location.href);

                        } else if (xhr.status === 504) {
                            this.layout.showServerSideTimeoutPage();
                            // send a ga event too, so we can see
                            // how often this happens...
                            typeof window.ga === "function" &&
                                ga('send', 'event', 'learnrouter', 'servertimeout', window.location.href);
                        // show the error based on response from backend
                        } else {
                            if (xhr.responseJSON) {
                                this.layout.showErrorPage(xhr);
                            } else {
                                window.location.reload();
                            }
                        }
                    }
                },
                // 95 seconds without a response, and we show the timeout page
                "timeout": 95000
            });
        },

        "xhrProgress": function () {
            var xhr = new window.XMLHttpRequest();

            xhr.addEventListener("progress", function (evt) {
              if (evt.lengthComputable) {
                nprogress.inc(.85 * evt.loaded / evt.total);
              }
            }, false);

            this.context.currentNativeXHR = xhr;

            return xhr;
        },

        "doFormPost": function (xhrParams) {
            var ajaxSuccess;

            if (this.currentXHR && this.currentXHR.readystate !== 4){
                this.currentXHR.abort();
            }

            nprogress.set(0);
            nprogress.inc(.1);

            xhrParams = _.extend({
                "context": this,
                "xhr": this.xhrProgress
            }, xhrParams);

            ajaxSuccess = function (data) {
                if (data.status && data.status === 401) {
                    // note not using the actual response status because of how the resphandler breaks the status codes
                    // this will be fixed in EDU-5100
                    window.location.href = "/learn/signin?next=" + encodeURIComponent(data.next);
                } else {
                    // We already have the app-data on hand so we
                    // set it on the application to use on route
                    // update.
                    this.appArgs.data = data;

                    var responseURL = this.currentNativeXHR.getResponseHeader('X-Location');

                    // TODO: it would be nice to make the cache bust static key change detection work at this level
                    // as well, so that we have less round trips to the server when the code was updated.  Currently,
                    // if the URL changed between the current path & the X-Location, then the else block below will
                    // end up using fetchData internally, which will implement the front-end X-CBSK check, but
                    // there is an edge case where if the URL is the same for the POST, then we won't fully reload
                    // the page and old static files may still persist.

                    if (responseURL === window.location.pathname) {

                        // same URL as current route
                        this.appReady(
                            this.appArgs.App,
                            this.appArgs.action,
                            this.appArgs.appName,
                            this.appArgs.data
                        );
                    } else {
                        // We don't know if the new route is in the same app
                        // or in a different app; so we make the router go
                        // through the motions.

                        Backbone.history.navigate(
                            responseURL,
                            {
                                "trigger": true
                            }
                        );
                    }
                }
            };

            this.currentXHR = $.ajax(xhrParams).done(ajaxSuccess);
        }
    });
});

